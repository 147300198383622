<template>
  <div>
    <div class="row justify-content-center bg-black new-user ">
      <div class="col-12 col-sm-8 col-md-6 d-flex flex-column justify-content-center bg-white">
        <LanguageLoader routeLanguage="./translations/accounts/messages/newUser_"
          class="p-2 p-lg-5 animated fadeInUp text-center" />
      </div>
    </div>
  </div>
</template>

<script>
  import LanguageLoader from '@/components/TranslationsLoader.vue'
  export default {
    components: {
      LanguageLoader,
    },
    mounted() {},
  };
</script>

<style lang="scss">
.new-user{
  min-height: 100vh;
}
</style>